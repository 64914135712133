import React from 'react';
import mess from ".././Images/message.png"
const WhatsAppIcon = () => {
  const containerStyle = {
    position: "fixed",
    bottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 20px",
    boxSizing: "border-box",
  };

  const buttonStyle = {
    height: "53px",
    width: "53px",
  };
  return (
    
    <div style={containerStyle}>
    {/* WhatsApp Button */}
    {/* <a href="https://wa.link/h8llm8">
      <img src="https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo-whatsapp-512.png" style={buttonStyle} alt="WhatsApp" />
    </a> */}


   
  </div>

  );
};

export default WhatsAppIcon;
