import { BeatLoader} from "react-spinners";
import "./loader.css"
const Loader = () => {
    return (
        <div className="loader">
  <BeatLoader/>
        </div> 
    );
}

export default Loader;